import React from 'react'
import Layout from '../components/layout'
import GitTalk from '../components/gittalk'

const Talk = () => (
	<Layout>
		<GitTalk />
	</Layout>
)

export default Talk
